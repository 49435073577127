import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Title } from 'react-admin';
import { YEAR_RANGE } from '../index';
import { TODAY } from '../../../libs/sgx/shared/date';
import LegalEntityPayrollCard from './LegalEntityPayrollCard';
import {
    DateFilterComponent,
    PieChartField,
} from '../../../libs/sgx/components';

const COLORS_SERIES = {
    'Informal': '#4A90E2',
    'Lamansys SAS': '#50E3C2', 
    'S4': '#F5A623', 
    'Universidad': '#9013FE', 
};

const PayrollPage = () => {
    const [selectedYear, setSelectedYear] = useState(TODAY.year.toString());
    const handleDateRangeSelect = (year) => {
        setSelectedYear(year);
    };

    const dateFilters = [
        {
            "member": "LegalEntityPayroll.fecha",
            "operator": "inDateRange",
            "values": [`${selectedYear}-01-01`,`${selectedYear}-12-31`],
        },
    ];
    const keyMapper = (key) => {
        const legalEntity = key.split(',')[0];
        return {
            label: legalEntity,
            borderColor: COLORS_SERIES[legalEntity] || '#DDDDDD',
        }
    }
    return (
        <>
            <Title title="Retribuciones" />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h6" display="block">
                                Personas por entidad legal
                            </Typography>
                            <div style={{ height: '40vh' }}>
                                <PieChartField
                                    measures={[
                                        "LegalEntityPayroll.personas"
                                    ]}
                                    dimensions={[
                                        "LegalEntityPayroll.legal_entity_name"
                                    ]}
                                    filters={[...dateFilters]}
                                    keyMapper={keyMapper}
                                />
                            </div>
                        </CardContent>
                        <CardActions>
                            <Button size="small" href="#/legal-entities">Ver Entidades</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Card>
                        <CardContent>
                            <LegalEntityPayrollCard year={selectedYear} keyMapper={keyMapper}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

    </>
)};

export default PayrollPage;