import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LineDimentionChart from '../charts/LineDimentionChart';


const LegalEntityPayrollCard = ({ year, keyMapper, legalEntityId }) => {

    const dateFilters = [
        {
            "member": "LegalEntityPayroll.fecha",
            "operator": "inDateRange",
            "values": [`${year}-01-01`,`${year}-12-31`],
        },
    ];

    const timeDimensions = [
        {
            "dimension": "LegalEntityPayroll.fecha",
            "granularity": "month",
        }
    ];
    
    const title = legalEntityId ? 'Suma mensual de las retribuciones por detalle' : 'Suma mensual de las retribuciones en cada moneda por entidad legal';
    const dimensions = legalEntityId ? ["LegalEntityPayroll.detalle"] : ["LegalEntityPayroll.legal_entity_name"];

    const legalEntityIdFilters = legalEntityId ? [
        {
            "member": "LegalEntityPayroll.legal_entity_id",
            "operator": "equals",
            "values": [
                `${legalEntityId}`
            ]
        }
    ] : [];

    return (
        <>
            <Typography gutterBottom variant="h6" display="block">
                {title}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle2" display="block">
                        Retribuciones en pesos
                    </Typography>
                    <div style={{ height: '40vh' }}>
                        <LineDimentionChart
                            measures={[
                                "LegalEntityPayroll.monto_pesos"
                            ]}
                            dimensions={dimensions}
                            timeDimensions={timeDimensions}
                            filters={[...dateFilters, ...legalEntityIdFilters ]}
                            keyMapper={keyMapper}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle2" display="block">
                        Retribuciones en dolares
                    </Typography>
                    <div style={{ height: '40vh' }}>
                        <LineDimentionChart
                            measures={[
                                "LegalEntityPayroll.monto_dolares"
                            ]}
                            dimensions={dimensions}
                            timeDimensions={timeDimensions}
                            filters={[...dateFilters, ...legalEntityIdFilters ]}
                            keyMapper={keyMapper}
                        />        
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default LegalEntityPayrollCard;
