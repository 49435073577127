import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import SmallLineChart from '../charts/SmallLineChart';
import DateFilterComponent from '../../../libs/sgx/components/DateFilterComponent';
import { TODAY } from '../../../libs/sgx/shared/date';
import { YEAR_RANGE } from '../index';

const PersonaMensualLineChart = ({personaId}: {
  personaId: number,
}) => {
  const [selectedYear, setSelectedYear] = useState<string>(TODAY.year.toString());
  const handleDateRangeSelect = (year: string) => {
    setSelectedYear(year);
  };
  const timeDimensions = [
    {
      "dimension": "RetribucionesPorProyectos.fecha",
      "granularity": "month",
      "dateRange": [`${selectedYear}-01-01`,`${selectedYear}-12-31`],
    }
  ];
  return (
    <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <div style={{ height: '120px', width: '100%' }}>
              <SmallLineChart
                measures={[
                  "RetribucionesPorProyectos.valor_hora",
                ]}
                filters={[
                  {
                    "member": "RetribucionesPorProyectos.persona_id",
                    "operator": "equals",
                    "values": [
                      personaId.toString()
                    ]
                  }
                ]}
                timeDimensions={timeDimensions}
                />
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
        </Grid>
    </Grid>
)};

export default PersonaMensualLineChart;