import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useRecordContext } from 'react-admin';
import { TODAY } from '../../../libs/sgx/shared/date';
import DateFilterComponent from '../../../libs/sgx/components/DateFilterComponent';
import { YEAR_RANGE } from '../../../layout/dashboard/index';
import BusinessUnitMensualLineChart from '../../../layout/dashboard/proyectos/BusinessUnitMensualLineChart';
import BusinessUnitMensualTable from '../../../layout/dashboard/proyectos/BusinessUnitMensualTable';

const BusinessUnitMensualChart = (props: any) => {
    const record = useRecordContext(props);
    const [selectedYear, setSelectedYear] = useState<string>(TODAY.year.toString());
    const handleDateRangeSelect = (year: string) => {
      setSelectedYear(year);
    };
    const businessUnitId: number = record.id.valueOf() as number;
    return (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle2" display="block">
              Total mensual convertido a dolar blue al valor del día del pago
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <BusinessUnitMensualLineChart year={selectedYear} businessUnitId={businessUnitId} measures="RetribucionesPorProyectos.monto_dolares"/>
          </Grid>
          <Grid item xs={12} sm={12}>
            <BusinessUnitMensualTable year={selectedYear} businessUnitId={businessUnitId}/>
          </Grid>
          
        </Grid>
    );
};

export default BusinessUnitMensualChart;


