import React from 'react';
import { Resource } from 'react-admin';
import SGXPermissions from '../libs/sgx/auth/SGXPermissions';

import personas from './personas';
import asignacionescontratadas from './asignacionescontratadas';
import retribuciones from './retribuciones';
import asignaciones from './asignaciones';
// work
import customers from './customers';
// banking
import cuentas from './cuentas';
import movimientos from './movimientos';
import categoriaMovimiento from './categoria-movimiento';
// pivot
import asignacionPivot from './asignacion-pivot';
import asignacionContratadaPivot from './asignacion-contratada-pivot';
import retribucionPivot from './retribucion-pivot';
import retribucionProyectoPivot from './retribucion-proyecto-pivot';

import users from './users';
import passwordReset from './password-reset';
import dolar from './dolar';

import business from './business';
import resourcesStaff from './staff';
import resourcesPivot from './pivot';
import resourcesWork from './work';

export enum MenuGroup {
    EMPLOYEE,
    ACCOUNT,
    PROJECT,
    SALES,
    CONFIG,
};

const resources = (permissions: SGXPermissions) => [
    ...business(permissions),

    <Resource name="personas" {...personas(permissions)} />,
    <Resource name="asignacionescontratadas" {...asignacionescontratadas(permissions)} />,
    <Resource name="retribuciones" {...retribuciones(permissions)} />,
    <Resource name="asignaciones" {...asignaciones(permissions)} />,
    ...resourcesStaff(permissions),

    ...resourcesWork(permissions),
    <Resource name="customers" {...customers(permissions)} />,

    <Resource name="cuentas" {...cuentas(permissions)} />,
    <Resource name="movimientos" {...movimientos(permissions)} />,
    <Resource name="resumenes" />,
    <Resource name="categoria-movimiento" {...categoriaMovimiento(permissions)} />,

    <Resource name="asignacion-pivot" {...asignacionPivot(permissions)} />,
    <Resource name="asignacion-contratada-pivot" {...asignacionContratadaPivot(permissions)} />,
    <Resource name="retribucion-pivot" {...retribucionPivot(permissions)} />,
    <Resource name="retribucion-proyecto-pivot" {...retribucionProyectoPivot(permissions)} />,
    ...resourcesPivot(permissions),
    
    <Resource name="users" {...users(permissions)} />,
    <Resource name="dolar" {...dolar(permissions)} />,
    
    <Resource name="provincias" />,
    <Resource name="departamentos" />,
    <Resource name="localidades" />,
    <Resource name="password-reset" {...passwordReset} />,
    <Resource name="roles" />,
    <Resource name="generos" />,
    <Resource name="tipos-identificaciones" />,
];

export default resources;