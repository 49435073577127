import React from 'react';
import {
    ReferenceInput,
    DateInput,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    required,
    SelectInput,
} from 'react-admin';

import PersonaInput from '../personas/PersonaAutocompleteInput';

const redirect = (basePath, id, data) => `/personas/${data.personaId}/show/retribuciones`;

const RetribucionesCreate = props => (
    <Create {...props}>
        <SimpleForm redirect={redirect}>
            <ReferenceInput source="personaId" reference="personas" perPage={100} validate={[required()]}>
                <PersonaInput />
            </ReferenceInput>
            <ReferenceInput source="legalEntityId" reference="legal-entities" 
                sort={{ field: 'name', order: 'ASC' }}
                validate={[required()]}>
                <SelectInput optionText="name" optionValue="id"/>
            </ReferenceInput>
            <TextInput source="detalle" validate={[required()]}/>
            <NumberInput source="montoPesos"/>
            <NumberInput source="montoDolares"/>
            <DateInput source="fechaInicio" validate={[required()]}/>
            <DateInput source="fechaFin" validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export default RetribucionesCreate;