import React from 'react';
import { useRecordContext } from 'react-admin';

interface ShowFieldTitleProps {
    source: string;
}

const ShowFieldTitle = ({ source }: ShowFieldTitleProps) => {
    const record = useRecordContext();
    return (
        <>
            {record ? record[source] : ''}
        </>
    );
};

export default ShowFieldTitle;