import React from 'react';
import {
    Resource
} from 'react-admin';
import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';

import businessUnits from './business-units';
import legalEntities from './legal-entities';

const resourcesWork = (permissions: SGXPermissions) => [
    <Resource name="business-units" {...businessUnits(permissions)} />,
    <Resource name="legal-entities" {...legalEntities(permissions)} />,
];

export default resourcesWork;