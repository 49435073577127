import React from 'react';
import Grid from '@material-ui/core/Grid';
import SmallLineChart from '../charts/SmallLineChart';

const BusinessUnitMensualLineChart = ({year, businessUnitId, measures}: {
  businessUnitId: number,
  year: string,
  measures: string,
}) => {

  const timeDimensions = [
    {
      "dimension": "RetribucionesPorProyectos.fecha",
      "granularity": "month",
      "dateRange": [`${year}-01-01`,`${year}-12-31`],
    }
  ];
  return (
    <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <div style={{ height: '120px', width: '100%' }}>
              <SmallLineChart
                measures={[
                  measures,
                ]}
                filters={[
                  {
                    "member": "RetribucionesPorProyectos.business_unit_id",
                    "operator": "equals",
                    "values": [
                      businessUnitId.toString()
                    ]
                  }
                ]}
                timeDimensions={timeDimensions}
                />
          </div>
        </Grid>
    </Grid>
)};

export default BusinessUnitMensualLineChart;