import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ChartTableField } from '../../libs/sgx/components';
import { currencyFormatter, Divisa } from '../../libs/sgx/shared/currency';
import {
    ProyectoBalanceSimpleTimelineChart
} from './charts';

const monedaFormatter = currencyFormatter(Divisa.DOLAR);
const dolarValueFormatter = ({ value }) => monedaFormatter(value);

const ResumenProyectosCard = () => {
    const dateRange = [
        '2019-01-01',
        '2025-06-30',
      ];
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography gutterBottom variant="h6" display="block">
                    Balance simple (Ingresos - Egresos)
                </Typography>
                <div style={{ height: '360px', width: '100%' }}>
                    <ProyectoBalanceSimpleTimelineChart dateRange={dateRange} />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Typography gutterBottom variant="h6" display="block">
                    Balance de Unidades de Negocio
                </Typography>
                <ChartTableField
                    measures={[
                        `ResumenProyectos.balance_dolares`,
                        `ResumenProyectos.futuro_dolares`,
                        `ResumenProyectos.sueldo_dolares`,
                        `ResumenProyectos.ingreso_dolares`,
                    ]}
                    dimensions={[
                        "ResumenProyectos.business_unit_id",
                        "ResumenProyectos.business_unit_name",
                    ]}
                    order={{
                        "ResumenProyectos.futuro_dolares": "desc",
                        "ResumenProyectos.balance_dolares": "asc"
                    }}
                    columns={[
                        {
                            "field": "ResumenProyectos.business_unit_name",
                            "headerName": "Unidad de negocio",
                            "width": 280,
                            renderCell: ({ row }) => {
                                const url = `#/business-units/${row['ResumenProyectos.business_unit_id']}/show`;
                                return (
                                    <Button href={url} >
                                        {row['ResumenProyectos.business_unit_name']}
                                    </Button>
                                )
                            },
                        },
                        {
                            "field": `ResumenProyectos.balance_dolares`,
                            "headerName": "Balance",
                            "width": 180,
                            description: 'Ingresos menos RRHH',
                            valueFormatter: dolarValueFormatter,
                            align: 'right',
                        },
                        {
                            "field": `ResumenProyectos.futuro_dolares`,
                            "headerName": "Futuro Ingreso",
                            "width": 180,
                            description: 'Ingresos pendientes por el proyecto',
                            valueFormatter: dolarValueFormatter,
                            align: 'right',
                        },
                        {
                            "field": `ResumenProyectos.sueldo_dolares`,
                            "headerName": "Salió",
                            "width": 180,
                            description: 'Sueldos del proyecto',
                            valueFormatter: dolarValueFormatter,
                            align: 'right',
                        },
                        {
                            "field": `ResumenProyectos.ingreso_dolares`,
                            "headerName": "Entró",
                            "width": 180,
                            description: 'Ingresos del proyecto',
                            valueFormatter: dolarValueFormatter,
                            align: 'right',
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
}

export default ResumenProyectosCard;
