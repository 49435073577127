import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { currencyFormatter, Divisa } from '../../../libs/sgx/shared/currency';
import RetribucionesPorBusinesUnitMensualTable from '../charts/RetribucionesPorBusinesUnitMensualTable';

const coinFormatter = currencyFormatter(Divisa.DOLAR);
const dolarValueFormatter = ({ value }: {value: number}) => value ? coinFormatter(value): '';
const peopleValueFormatter = ({ value }: {value: number}) => value ? `${value}`: '';



const BusinessUnitMensualTable = ({year, businessUnitId}: {
  businessUnitId: number,
  year: string,
}) => {

  const dimensions = [
    "RetribucionesPorProyectos.proyecto_nombre",
    "RetribucionesPorProyectos.proyecto_id",
  ];

  const renderCell = ({ row }: any) => {
    const url = `#/proyectos/${row['RetribucionesPorProyectos.proyecto_id']}/show`;
    return (
        <Button href={url} >
            {row['RetribucionesPorProyectos.proyecto_nombre']}
        </Button>
    )
  };
  
  return (
    <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle2" display="block">
            Total mensual por proyecto convertido a dolar blue al valor del día del pago
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <RetribucionesPorBusinesUnitMensualTable
            year={year} businessUnitId={businessUnitId} 
            measures="RetribucionesPorProyectos.monto_dolares"
            firstColumn={{
              "field": "RetribucionesPorProyectos.proyecto_nombre",
              "headerName": "Proyecto",
              "width": 280,
              renderCell,
            }}
            dimensions={dimensions}
            valueFormatter={dolarValueFormatter}
            />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle2" display="block">
            Gente asignada mensualmente por proyecto
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
        <RetribucionesPorBusinesUnitMensualTable
            year={year} businessUnitId={businessUnitId} 
            measures="RetribucionesPorProyectos.personas"
            firstColumn={{
              "field": "RetribucionesPorProyectos.proyecto_nombre",
              "headerName": "Proyecto",
              "width": 280,
              renderCell,
            }}
            dimensions={dimensions}
            valueFormatter={peopleValueFormatter}
            />
        </Grid>
    </Grid>
)};

export default BusinessUnitMensualTable;