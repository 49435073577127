import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
} from 'react-admin';

import PersonaField from '../personas/PersonaField';

const RetribucionesShow = props => (
    <Show {...props} >
        <SimpleShowLayout>
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <ReferenceField source="legalEntityId" reference="legal-entities" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="detalle" />
            <NumberField source="montoPesos" locales="es-AR" options={{style: 'currency', currency: 'ARS'}}/>
            <NumberField source="montoDolares" locales="es-AR" options={{style: 'currency', currency: 'USD'}}/>
            <DateField source="fechaInicio" locales="es-AR" options={{ timeZone: 'UTC' }}/>
            <DateField source="fechaFin" locales="es-AR" options={{ timeZone: 'UTC' }}/>
        </SimpleShowLayout>
    </Show>
);

export default RetribucionesShow;