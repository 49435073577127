import React from 'react';
import {
    DateField,
    ReferenceField,
    Show,
    TabbedShowLayout,
    TextField,
    ReferenceManyField,
    Datagrid,
    Tab,
    EditButton,
    NumberField,
    CloneButton,
    Pagination,
    TabbedShowLayoutTabs,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
    DATE_OPTIONS,
    isDatePast,
} from '../../libs/sgx/shared/date';
import {
    ShowFormTitle,
} from '../../libs/sgx/components';

import CreateRelatedButton from '../components/CreateRelatedButton';
import { fullName } from './utils';
import {
    PAYROLL_ROLES,
} from '../roles-set';
import PersonaMovimientos from './PersonaMovimientos';
import PersonaRetribucionesSummary from './components/PersonaRetribucionesSummary';

const useTabsStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
}));

const postRowStyle = (record, index) => {
    const old = isDatePast(record.fechaFin);
    return ({
        backgroundColor: old ? '#ddd' : 'white',
    })
};

const PersonaShow = (props) => {
    const accesoARetribuciones = props.permissions?.hasAnyAssignment(...PAYROLL_ROLES);

    const classes = useTabsStyles();

    return (
  <Show {...props} title={<ShowFormTitle mapper={fullName}/>}>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="on" className={classes.root} />}>
        <Tab label="resources.personas.tabs.general">
            <TextField source="cuil" />
            <TextField source="nombre" />
            <TextField source="apellido" />
            <TextField source="telefono" />
            <TextField source="email" />
            <CreateRelatedButton
                reference="asignacionescontratadas"
                refFieldName="personaId"
                label="resources.personas.buttons.asignacionescontratadas"
            />
            <ReferenceManyField
                addLabel={false}
                reference="asignacionescontratadas"
                target="personaId"
                sort={{ field: 'fechaInicio', order: 'DESC' }}
                pagination={<Pagination />}
            >
                <Datagrid rowClick="show" rowStyle={postRowStyle}>
                    <TextField source="detalle" />
                    <TextField source="horasMensuales" />
                    <DateField source="fechaInicio" locales="es-AR" options={DATE_OPTIONS}/>
                    <DateField source="fechaFin" locales="es-AR" options={DATE_OPTIONS}/>
                    <EditButton />
                </Datagrid>
            </ReferenceManyField>
            <DateField source="fechaInicioPladema" locales="es-AR" options={DATE_OPTIONS}/>
            <ReferenceField source="generoId" reference="generos" link={false}>
                <TextField source="nombre" />
            </ReferenceField>
            <DateField source="fechaNacimiento" locales="es-AR" options={DATE_OPTIONS}/>
        </Tab>
        {accesoARetribuciones &&
            <Tab label="resources.personas.tabs.retribuciones" path="retribuciones">
                <PersonaRetribucionesSummary />
                <CreateRelatedButton
                    reference="retribuciones"
                    refFieldName="personaId"
                    label="resources.personas.buttons.retribuciones"
                />
                <ReferenceManyField
                    addLabel={false}
                    reference="retribuciones"
                    target="personaId"
                    sort={{ field: 'fechaInicio', order: 'DESC' }}
                    pagination={<Pagination />}
                >
                    <Datagrid rowClick="show" rowStyle={postRowStyle}>
                        <TextField source="detalle" />
                        <DateField source="fechaInicio" locales="es-AR" options={DATE_OPTIONS}/> 
                        <DateField source="fechaFin" locales="es-AR" options={DATE_OPTIONS}/> 
                        <NumberField source="montoPesos" locales="es-AR" options={{style: 'currency', currency: 'ARS'}}/>
                        <NumberField source="montoDolares" locales="es-AR" options={{style: 'currency', currency: 'USD'}}/>
                        <ReferenceField source="legalEntityId" reference="legal-entities" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                        <EditButton />
                        <CloneButton />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        }
        <Tab label="resources.personas.tabs.asignaciones" path="asignaciones">
            <CreateRelatedButton
                reference="asignaciones"
                refFieldName="personaId"
                label="resources.personas.buttons.asignaciones"
            />
            <ReferenceManyField
                addLabel={false}
                reference="asignaciones"
                target="personaId"
                sort={{ field: 'fechaInicio', order: 'DESC' }}
                pagination={<Pagination />}
            >
                <Datagrid rowClick="show" rowStyle={postRowStyle}>
                        <ReferenceField source="proyectoId" reference="proyectos" link="show">
                            <TextField source="nombre" />
                        </ReferenceField>
                        <ReferenceField source="jobId" reference="jobs" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="detalle" />
                        <TextField source="horasMensuales" />
                        <DateField source="fechaInicio" locales="es-AR" options={DATE_OPTIONS}/>
                        <DateField source="fechaFin" locales="es-AR" options={DATE_OPTIONS}/>
                        <EditButton />
                </Datagrid>

            </ReferenceManyField>
        </Tab>
        {accesoARetribuciones && <Tab label="resources.proyectos.tabs.grafico" path="grafico">
            <DateField source="fechaInicioPladema" locales="es-AR" options={DATE_OPTIONS}/>
            <PersonaMovimientos source="id"/>
        </Tab>}
    </TabbedShowLayout>
  </Show>
    )
};

export default PersonaShow;