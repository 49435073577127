import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';

import SGXPermissions from '../../../libs/sgx/auth/SGXPermissions';

import {
    PAYROLL_ROLES,
} from '../../roles-set';

import CuentaPersonalCreate from './CuentaPersonalCreate';
import CuentaPersonalList from './CuentaPersonalList';
import CuentaPersonalEdit from './CuentaPersonalEdit';

const cuentas = (permisos: SGXPermissions) => ({
    icon: MoveToInboxIcon,
    create: CuentaPersonalCreate,
    edit: CuentaPersonalEdit,
    list: permisos.hasAnyAssignment(...PAYROLL_ROLES) ? CuentaPersonalList : undefined,
    options: {
        submenu: 'staff'
    }
    
});

export default cuentas;
