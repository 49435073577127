import SGXPermissions from '../../../libs/sgx/auth/SGXPermissions';
import {
    BUSINESS_ROLES,
    PAYROLL_ROLES,
} from '../../roles-set';

import BookmarkIcon from '@material-ui/icons/Bookmark';

import LegalEntityCreate from './LegalEntityCreate';
import LegalEntityList from './LegalEntityList';
import LegalEntityShow from './LegalEntityShow';
import LegalEntityEdit from './LegalEntityEdit';

const legalEntities = (permisos: SGXPermissions) => ({
    icon: BookmarkIcon,
    create: permisos.hasAnyAssignment(...BUSINESS_ROLES) ? LegalEntityCreate : undefined,
    edit: permisos.hasAnyAssignment(...BUSINESS_ROLES) ? LegalEntityEdit : undefined,
    list: permisos.hasAnyAssignment(...PAYROLL_ROLES) ? LegalEntityList : undefined,
    show: LegalEntityShow,
    options: {
        submenu: 'business'
    }
});

export default legalEntities;
