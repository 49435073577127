import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';

import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DataGrid } from '@material-ui/data-grid';

import { cubejsApiTokenPromise, cubejsOptions } from '../../../layout/dashboard/charts/cube-config';

const cubejsApi = cubejs(cubejsApiTokenPromise, cubejsOptions);

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  title: {
    fontSize: 14,
  },
});

const renderChart = ({ resultSet, error, pivotConfig, columns, title }) => {
  if (error) {
      return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
      return <LinearProgress />;
  }
  
  const columnsDataSource = columns || resultSet.tableColumns(pivotConfig).map(
      ({key, shortTitle}) => ({field: key, headerName: shortTitle, width: 200})
  );

  const rows = resultSet.tablePivot(pivotConfig).map(
      ({key, ...rest}, index) => ({id: index, ...rest})
  );

  // console.log('table', {columns, columnsDataSource, rows})
  
  return (
      <div style={{ width: '100%' }}>
          <Typography variant="inherit" color="textSecondary">
              {title}
          </Typography>
          <DataGrid
              size="small"
              rows={rows}
              columns={columnsDataSource}
              autoHeight={true}
              // pageSize={50}
              disableSelectionOnClick
          />
      </div>
  );

};

const ChartTableField = ({ measures, filters, dimensions, order, segments = undefined, timeDimensions, ...props }) => {
  const classes = useStyles();

  return (
    <QueryRenderer
      query={{
        "measures": measures,
        "timeDimensions": timeDimensions,
        "order": order,
        "dimensions": dimensions,
        "filters": filters,
        "segments": segments,
      }}
      cubejsApi={cubejsApi}
      resetResultSetOnChange={false}
      render={(renderData) => renderChart({
        ...renderData,
        classes,
        ...props,
      })}
    />
  );
};

export default ChartTableField;
