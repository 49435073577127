import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Title } from 'react-admin';
import { YEAR_RANGE } from '../index';
import { TODAY, isDatePast, dateISOFormatter, monthFormatter } from '../../../libs/sgx/shared/date';
import ContratacionesCard from '../ContratacionesCard';

import { 
    ChartTableField,
    DateFilterComponent,
} from '../../../libs/sgx/components';

const maxToday = (dateStr) => isDatePast(dateStr) ? dateStr : dateISOFormatter(new Date().toISOString());

const AsignacionesPage = () => {
    const [selectedYear, setSelectedYear] = useState(TODAY.year.toString());
    const handleDateRangeSelect = (year) => {
        setSelectedYear(year);
    };
    const dateFrom = `${selectedYear}-01-01`;
    const dateTo = maxToday(`${selectedYear}-12-31`);
    const tableFrom = monthFormatter(dateFrom);
    const tableTo = monthFormatter(dateTo);

    const dateFilters = [
        {
            "member": "Contrataciones.fecha",
            "operator": "inDateRange",
            "values": [dateFrom, dateTo ],
        },
        {
            "member": "Contrataciones.horas_sin_asignar",
            "operator": "gt",
            "values": [`0`],
        },
    ];

    return (
    <>
        <Title title="Asignaciones" />
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Card>
                    <CardContent>
                        <ContratacionesCard year={selectedYear} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={12}>
            <Card>
                <CardContent>
                    <Typography gutterBottom variant="h6" display="block">
                        Detalle de horas sin asignar
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                        Desde {tableFrom} hasta {tableTo}
                    </Typography>
                    <ChartTableField
                        measures={[
                            `Contrataciones.horas_sin_asignar`,
                            `Contrataciones.horas_contratadas`,
                            `Contrataciones.horas_asignadas`,
                        ]}
                        dimensions={[
                            "Contrataciones.persona_id",
                            "Contrataciones.apellido",
                            "Contrataciones.nombre",
                        ]}
                        filters={[...dateFilters ]}
                        order={{
                            "Contrataciones.horas_sin_asignar": "desc",
                        }}
                        columns={[
                            {
                                "field": "Contrataciones.apellido",
                                "headerName": "Apellido",
                                "width": 280,
                                renderCell: ({ row }) => {
                                    const url = `#/personas/${row['Contrataciones.persona_id']}/show/asignaciones`;
                                    return (
                                        <Button href={url} >
                                            {row['Contrataciones.apellido']}
                                        </Button>
                                    )
                                },
                            },
                            {
                                "field": `Contrataciones.nombre`,
                                "headerName": "Nombre",
                                "width": 180,
                                description: 'Nombre',
                                align: 'right',
                            },
                            {
                                "field": `Contrataciones.horas_sin_asignar`,
                                "headerName": "Sin asignar",
                                "width": 180,
                                description: 'Horas sin asignar',
                                align: 'right',
                            },
                            {
                                "field": `Contrataciones.horas_contratadas`,
                                "headerName": "Contratadas",
                                "width": 180,
                                description: 'Horas contratadas',
                                align: 'right',
                            },
                            {
                                "field": `Contrataciones.horas_asignadas`,
                                "headerName": "Asignadas",
                                "width": 180,
                                description: 'Horas asignar',
                                align: 'right',
                            },
                            
                            
                        ]}
                    />
                </CardContent>
            </Card>
            </Grid>

        </Grid>
    </>
)};

export default AsignacionesPage;