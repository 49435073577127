import React from 'react';
import Button from '@material-ui/core/Button';

import {
    useTranslate,
} from 'react-admin';

import { currencyFormatter, Divisa } from '../../../libs/sgx/shared/currency';
import { dayFormatter } from '../../../libs/sgx/shared/date';
import ChartTableField from '../../../libs/sgx/components/ChartTableField';

const dayValueFormatter = ({ value }) => dayFormatter(value);

const ProyectosTable = ({id, esProyecto}) => {
    const translate = useTranslate();

    const monedaFormatter = currencyFormatter(Divisa.DOLAR);
    const dolarValueFormatter = ({ value }) => monedaFormatter(value);
    const title = esProyecto ? 'Proyectos': 'Contratos';
    const nameLabel = esProyecto ? 'Proyecto': 'Contrato';

    const segments = esProyecto ? ["ResumenProyectos.esProyecto"] : ["ResumenProyectos.esContrato"];

    return (
    <ChartTableField
        title={title}
        measures={[
            `ResumenProyectos.balance_dolares`,
            `ResumenProyectos.futuro_dolares`,
            `ResumenProyectos.ingreso_dolares`,
            `ResumenProyectos.sueldo_dolares`,
        ]}
        dimensions={[
            "ResumenProyectos.id",
            "ResumenProyectos.nombre",
            "ResumenProyectos.fecha_inicio_asignaciones",
            "ResumenProyectos.fecha_fin_asignaciones",

        ]}
        segments={segments}
        filters={[
            {
                "member": "ResumenProyectos.business_unit_id",
                "operator": "equals",
                "values": [
                    `${id}`
                ]
            }
        ]}
        columns={[
            {
                "field": "ResumenProyectos.nombre",
                "headerName": nameLabel,
                "width": 180,
                renderCell: ({ row }) => {
                    const url = `#/proyectos/${row['ResumenProyectos.id']}/show`;
                    return (
                        <Button href={url} >
                            {row['ResumenProyectos.nombre']}
                        </Button>
                    )
                },
            },
            {
                "field": `ResumenProyectos.balance_dolares`,
                "headerName": translate('cubes.ResumenProyectos.balance_dolares'),
                "width": 180,
                description: 'Ingresos menos asignaciones',
                valueFormatter: dolarValueFormatter,
                align: 'right',
            },
            {
                "field": `ResumenProyectos.futuro_dolares`,
                "headerName": translate('cubes.ResumenProyectos.futuro_dolares'),
                "width": 180,
                description: 'Ingresos pendientes por el proyecto',
                valueFormatter: dolarValueFormatter,
                align: 'right',
            },
            {
                "field": `ResumenProyectos.sueldo_dolares`,
                "headerName": translate('cubes.ResumenProyectos.sueldo_dolares'),
                "width": 180,
                description: 'Inversión en sueldos para el proyecto',
                valueFormatter: dolarValueFormatter,
                align: 'right',
            },
            {
                "field": `ResumenProyectos.ingreso_dolares`,
                "headerName": translate('cubes.ResumenProyectos.ingreso_dolares'),
                "width": 180,
                description: 'Ingresos registrados por el proyecto',
                valueFormatter: dolarValueFormatter,
                align: 'right',
            },
            {
                "field": "ResumenProyectos.fecha_inicio_asignaciones",
                "headerName": "Primera asignación",
                valueFormatter: dayValueFormatter,
                "width": 200,
            },
            {
                "field": "ResumenProyectos.fecha_fin_asignaciones",
                "headerName": "Última asignación",
                valueFormatter: dayValueFormatter,
                "width": 200,
            },
        ]}
    />
)};

const BusinessUnitProyectos = ({ ...props }) => {

    return (
        <div>
            <ProyectosTable {...props} esProyecto={true}/>
            <ProyectosTable {...props} esProyecto={false}/>
        </div>
    )
};

export default BusinessUnitProyectos;
