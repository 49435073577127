import React from 'react';

import {
    List,
    ReferenceField,
    Datagrid,
    TextField,
    DateField,
    Filter,
    SelectInput,
    ReferenceInput,
    EditButton,
} from 'react-admin';

import { isDatePast } from '../../../libs/sgx/shared/date';

import PersonaField from '../../personas/PersonaField';
import { ESTADO_EJECUCION_VALUES } from '../../personas/utils';
import PersonaInput from '../../personas/PersonaAutocompleteInput';
import CuentaPersonalField from '../cuentas-personales/CuentaPersonalField';
import PagoEjecutadoMontoField from './PagoEjecutadoMontoField';
import PagoEjecutadoRowEdit from './PagoEjecutadoRowEdit';

import { DIVISAS_VALUES } from '../../cuentas/utils';

const PagosEjecutadosFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="estado" choices={ESTADO_EJECUCION_VALUES} />
        <ReferenceInput source="personaId" reference="personas" >
            <PersonaInput />
        </ReferenceInput>
        <ReferenceInput source="personaEncargadaId" reference="personas" >
            <PersonaInput />
        </ReferenceInput>
        <SelectInput
            source="divisa"
            choices={DIVISAS_VALUES}
        />
    </Filter>
);

const pagoPendienteRowStyle = (record) => ({
    backgroundColor: record.estado === 'NUEVO' && isDatePast(record.fechaEsperada) ? '#efe' : 'white',
});

const PagoEjecutadoList = props => (
    <List {...props} 
        filters={<PagosEjecutadosFilter />} 
        sort={{ field: 'fechaEsperada', order: 'DESC' }}>
        <Datagrid rowClick="expand" expand={<PagoEjecutadoRowEdit />} rowStyle={pagoPendienteRowStyle} >
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <DateField source="fechaEsperada" locales="es-AR" options={{ timeZone: 'UTC' }}/> 
            <TextField source="estado" />

            <PagoEjecutadoMontoField source="monto" preferSource="neto" />
            <ReferenceField source="cuentaPersonalId" reference="cuentas-personales" link={false}>
                <CuentaPersonalField />
            </ReferenceField>
            <TextField source="detalle" />
            <EditButton />
        </Datagrid>
    </List>
);

export default PagoEjecutadoList;