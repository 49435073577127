import * as React from "react";
import Grid from '@material-ui/core/Grid';

import {
    RowChartField,
    RowChartFieldData,
} from '../../../../libs/sgx/components';

import {
    genericCurrency
} from '../../../../libs/sgx/shared/currency';
import {
    dateISOFormatter,
} from '../../../../libs/sgx/shared/date';

const SummaryGrid = (props) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
                <RowChartFieldData {...props} source="balance_dolares" format={genericCurrency} />
            </Grid>
            <Grid item xs={6} sm={3}>
                <RowChartFieldData {...props} source="futuro_dolares" format={genericCurrency} />
            </Grid>
            <Grid item xs={6} sm={3}>
                <RowChartFieldData {...props} source="ingreso_dolares" format={genericCurrency} />
            </Grid>
            <Grid item xs={12} sm={12}>
                <RowChartFieldData {...props} source="sueldo_dolares" format={genericCurrency} />
            </Grid>
            <Grid item xs={6}>
                <RowChartFieldData {...props} source="fecha_inicio_asignaciones" format={dateISOFormatter} />
            </Grid>
            <Grid item xs={6}>
                <RowChartFieldData {...props} source="fecha_fin_asignaciones" format={dateISOFormatter} />
            </Grid>
            <Grid item xs={6}>
                <RowChartFieldData {...props} source="fecha_inicio" format={dateISOFormatter} />
            </Grid>
            <Grid item xs={6}>
                <RowChartFieldData {...props} source="fecha_fin" format={dateISOFormatter} />
            </Grid>
        </Grid>
    );
}


const ProjectSummary = ({
    proyectoId,
}) => {
    return (
        <RowChartField cube="ResumenProyectos"
            filters={[
                {
                    "member": "ResumenProyectos.id",
                    "operator": "equals",
                    "values": [
                        `${proyectoId}`
                    ]
                },
            ]}
            measures={[
                "balance_dolares",
                "futuro_dolares",
                "ingreso_dolares",
                "sueldo_dolares",
            ]}
            dimensions={[
                "fecha_inicio",
                "fecha_fin",
                "fecha_inicio_asignaciones",
                "fecha_fin_asignaciones",
            ]}
            title="Resumen"
        >
            <SummaryGrid />
        </RowChartField>
    );
};

export default ProjectSummary;
