import React from 'react';
import {
    Datagrid,
    ReferenceField,
    TextField,
    List,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    SearchInput,
} from 'react-admin';

import PersonaField from '../../personas/PersonaField';
import { fullName } from '../../personas/utils';

const CuentaPersonalFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="personaId" reference="personas" alwaysOn>
            <AutocompleteInput optionText={fullName} />
        </ReferenceInput>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const CuentaPersonalList = props => (
    <List {...props}
        filters={<CuentaPersonalFilter />}
    >
        <Datagrid rowClick="edit">
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <TextField source="tipo" />
            <TextField source="identificador" />
        </Datagrid>
    </List>
);

export default CuentaPersonalList;