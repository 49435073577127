import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFilterComponent from '../../../libs/sgx/components/DateFilterComponent';
import { monthFormatter, TODAY } from '../../../libs/sgx/shared/date';
import { currencyFormatter, Divisa } from '../../../libs/sgx/shared/currency';
import { YEAR_RANGE } from '../index';
import { ChartTableField } from '../../../libs/sgx/components';

const coinFormatter = currencyFormatter(Divisa.DOLAR);
const monedaFormatter = currencyFormatter(Divisa.PESO);
const dolarValueFormatter = ({ value }: {value: number}) => value ? coinFormatter(value): '';
const pesoValueFormatter = ({ value }: {value: number}) => value ? monedaFormatter(value): '';

const buildColumns = (selectedYear: string, dimention: string, valueFormatter: any) => {
  let columns = [];

  for (let month = 0; month < 12; month++) {
    const formattedMonth = String(month + 1).padStart(2, '0');
    columns.push(
      {
        "field": `${selectedYear}-${formattedMonth}-01T00:00:00.000,${dimention}`,
        "headerName": formattedMonth,
        "width": 150,
        description: monthFormatter(`${selectedYear}-${formattedMonth}-01`),
        valueFormatter: valueFormatter,
        align: 'right',
    }
    );
  }
  return columns;
}

const PersonaMensualTable = ({personaId}: {
  personaId: number,
}) => {
  const [selectedYear, setSelectedYear] = useState<string>(TODAY.year.toString());
  const handleDateRangeSelect = (year: string) => {
    setSelectedYear(year);
  };

  const pivotConfig = {
    "x": [
      "LegalEntityPayroll.detalle"
    ],
    "y": [
      "LegalEntityPayroll.fecha.month",
      "measures"
    ],
    "fillMissingDates": true,
    "joinDateRange": false
  };

  const timeDimensions = [
    {
      "dimension": "LegalEntityPayroll.fecha",
      "granularity": "month",
      "dateRange": [`${selectedYear}-01-01`,`${selectedYear}-12-31`],
    }
  ];
  
  return (
    <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <ChartTableField
              measures={[
                  `LegalEntityPayroll.monto_pesos`,
              ]}
              timeDimensions={timeDimensions}
              dimensions={[
                  "LegalEntityPayroll.detalle",
              ]}
              filters={[
                  {
                      "member": "LegalEntityPayroll.persona_id",
                      "operator": "equals",
                      "values": [
                          `${personaId}`
                      ]
                  },
                  {
                    "member": "LegalEntityPayroll.monto_pesos",
                    "operator": "gt",
                    "values": [
                        `0`
                    ]
                  },
              ]}
              order={{
                  "LegalEntityPayroll.monto_pesos": "desc",
              }}
              columns={[
                  {
                      "field": "LegalEntityPayroll.detalle",
                      "headerName": "Detalle",
                      "width": 280,
                  },
                  ...buildColumns(selectedYear, `LegalEntityPayroll.monto_pesos`, pesoValueFormatter)
              ]}
              pivotConfig={pivotConfig}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ChartTableField
              measures={[
                  `LegalEntityPayroll.monto_dolares`,
              ]}
              timeDimensions={timeDimensions}
              dimensions={[
                  "LegalEntityPayroll.detalle",
              ]}
              filters={[
                  {
                      "member": "LegalEntityPayroll.persona_id",
                      "operator": "equals",
                      "values": [
                          `${personaId}`
                      ]
                  },
                  {
                    "member": "LegalEntityPayroll.monto_dolares",
                    "operator": "gt",
                    "values": [
                        `0`
                    ]
                  }
              ]}
              order={{
                  "LegalEntityPayroll.monto_dolares": "desc",
              }}
              columns={[
                  {
                      "field": "LegalEntityPayroll.detalle",
                      "headerName": "Detalle",
                      "width": 280,
                  },
                  ...buildColumns(selectedYear, `LegalEntityPayroll.monto_dolares`, dolarValueFormatter)
              ]}
              pivotConfig={pivotConfig}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
        </Grid>
    </Grid>
)};

export default PersonaMensualTable;