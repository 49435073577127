import SGXPermissions from '../../../libs/sgx/auth/SGXPermissions';
import { HR_ROLES } from '../../roles-set';

import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import JobCreate from './JobCreate';
import JobList from './JobList';
import JobShow from './JobShow';
import JobEdit from './JobEdit';

const jobs = (permisos: SGXPermissions) => ({
    icon: LocalOfferIcon,
    create: JobCreate,
    edit: JobEdit,
    list: permisos.hasAnyAssignment(...HR_ROLES) ? JobList : undefined,
    show: JobShow,
    options: {
        submenu: 'staff'
    }
});

export default jobs;
