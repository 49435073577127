import * as React from 'react';
import { Route } from 'react-router-dom';
import PasswordResetPage from './password-reset';

import { AsignacionesPage } from '../dashboard/asignaciones';
import { IngresosPage } from '../dashboard/ingresos';
import { ProyectosPage } from '../dashboard/proyectos';
import { PayrollPage } from '../dashboard/plantilla';

const passwordResetUrl = (token) => `/auth/password-reset/${token}`;

const customRoutes = [
    <Route exact path={passwordResetUrl(':token')} render={() => <PasswordResetPage />} noLayout />,

    <Route exact path="/dashboard/asignaciones" component={AsignacionesPage} />,
    <Route exact path="/dashboard/ingresos" component={IngresosPage} />,
    <Route exact path="/dashboard/business-units" component={ProyectosPage} />,
    <Route exact path="/dashboard/payroll" component={PayrollPage} />,
];

export { customRoutes, passwordResetUrl };
