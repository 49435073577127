import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
} from 'react-admin';
import {
    BUSINESS_ROLES,
} from '../../roles-set';
import {
    TypographyField,
} from '../../../libs/sgx/components';

import LegalEntityShowCustomerDebtCard from './LegalEntityShowCustomerDebtCard';
import { ShowFieldTitle } from '../../../libs/sgx/components';
import LegalEntityPayrollChartField from './LegalEntityPayrollChartField';

const LegalEntityShow = ({
    ...props
}) => {
    const accesoAMovimientos = props.permissions?.hasAnyAssignment(...BUSINESS_ROLES);
    return (
        <Show title={<ShowFieldTitle source="name"/>} {...props} >
            <TabbedShowLayout>
                <Tab label="Resumen">
                    <TypographyField source="name" variant="h4"></TypographyField>
                    <LegalEntityPayrollChartField ></LegalEntityPayrollChartField>
                </Tab>
                <Tab label="Sin cobrar">
                    {accesoAMovimientos && <LegalEntityShowCustomerDebtCard />}            
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

export default LegalEntityShow;