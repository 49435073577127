import * as React from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import {
    RowChartField,
    RowChartFieldData,
} from '../../../libs/sgx/components';

import {
    genericCurrency
} from '../../../libs/sgx/shared/currency';

import { TODAY } from '../../../libs/sgx/shared/date';
import { useRecordContext } from 'react-admin';
import PersonaMensualLineChart from '../../../layout/dashboard/plantilla/PersonaMensualLineChart';
import PersonaMensualTable from '../../../layout/dashboard/plantilla/PersonaMensualTable';

const PersonaRetribucionesSummary = (props) => {
    const record = useRecordContext(props);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                        <RowChartField cube="MovimientosPorPersona"
                            filters={[
                                {
                                    "member": "MovimientosPorPersona.persona_id",
                                    "operator": "equals",
                                    "values": [
                                        `${record.id}`
                                    ]
                                },
                                {
                                    "member": "MovimientosPorPersona.anio",
                                    "operator": "equals",
                                    "values": [
                                        `${TODAY.year}`
                                    ]
                                },
                                {
                                    "member": "MovimientosPorPersona.mes",
                                    "operator": "equals",
                                    "values": [
                                        `${TODAY.month}`
                                    ]
                                },
                            ]}
                            measures={[
                                "a_cobrar_pesos",
                                "a_cobrar_dolares",
                            ]}
                            dimensions={[
                                "anio",
                                "mes",
                            ]}
                            order={{
                                "MovimientosPorPersona.anio": "desc",
                                "MovimientosPorPersona.mes": "desc"
                            }}
                        >
                            <RowChartFieldData icon="🇦🇷" source="a_cobrar_pesos" calculate={(row) => row && parseInt(row['MovimientosPorPersona.a_cobrar_pesos'] || 0, 10)} format={genericCurrency} />
                            <RowChartFieldData icon="🇺🇸" source="a_cobrar_dolares" />
                        </RowChartField>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PersonaMensualLineChart personaId={record.id}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        >
                            Cálculo mensual
                    </AccordionSummary>
                    <AccordionDetails>
                        <PersonaMensualTable personaId={record.id}/>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default PersonaRetribucionesSummary;


