import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useRecordContext } from 'react-admin';
import { TODAY } from '../../../libs/sgx/shared/date';
import DateFilterComponent from '../../../libs/sgx/components/DateFilterComponent';
import { YEAR_RANGE } from '../../../layout/dashboard/index';
import BusinessUnitMensualLineChart from '../../../layout/dashboard/proyectos/BusinessUnitMensualLineChart';
import RetribucionesPorBusinesUnitMensualTable from '../../../layout/dashboard/charts/RetribucionesPorBusinesUnitMensualTable';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const horasValueFormatter = ({ value }: {value: number}) => value ? `${value}`: '';

const BusinessUnitPeopleTable = (props: any) => {
    const record = useRecordContext(props);
    const [selectedYear, setSelectedYear] = useState<string>(TODAY.year.toString());
    const handleDateRangeSelect = (year: string) => {
      setSelectedYear(year);
    };
    const businessUnitId: number = record.id.valueOf() as number;

    const renderCell = ({ row }: any) => {
      const url = `#/personas/${row['RetribucionesPorProyectos.persona_id']}/show/asignaciones`;
      return (
        <>
        {row['RetribucionesPorProyectos.persona_nombre']}
          <IconButton aria-label="go" size="small"  href={url} >
            <OpenInNewIcon fontSize="inherit" />
          </IconButton>
          </>
      )
    };

    return (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle2" display="block">
              Personas asignadas por mes
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <BusinessUnitMensualLineChart year={selectedYear} businessUnitId={businessUnitId} measures="RetribucionesPorProyectos.personas"/>
          </Grid>
          <Grid item xs={12} sm={12}>
          <RetribucionesPorBusinesUnitMensualTable
              year={selectedYear} businessUnitId={businessUnitId} 
              measures="RetribucionesPorProyectos.horas_mensuales"
              firstColumn={{
                "field": "RetribucionesPorProyectos.persona_nombre",
                "headerName": "Persona",
                "width": 280,
                renderCell,
              }}
              dimensions={["RetribucionesPorProyectos.persona_nombre", "RetribucionesPorProyectos.persona_id"]}
              valueFormatter={horasValueFormatter}
              />
          </Grid>
          
        </Grid>
    );
};

export default BusinessUnitPeopleTable;


