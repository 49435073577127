
const messages = {
    app: {
        home: {
            title: 'Lamansers'
        },
        dashboard: {
            title: 'Lamansers',
            subtitle: 'Bienvenido',
        },
        menu: {
            business: 'Organización',
            staff: 'Planta',
            work: 'Trabajos',
            banking: 'Bancos',
            pivot: 'Vistas',
            more: 'Mas',

        },
    },
    error: {
        "role-level": {
            institution: {
                required: 'El rol requiere una institución'
            }
        },
        "resource1": {
            "errorCode1": "Error code 1",
        },
        forbidden: 'No tiene los permisos necesarios',
        'unknown': 'Ocurrió un error',
    },
    resources: {
        users: {
            name: 'Usuario |||| Usuarios',
            fields: {
                username: 'Nombre de usuario',
                personId: 'Persona',
                enable: 'Habilitado',
                lastLogin: 'Último ingreso',
                businessId: 'Unidad de negocio',
                roleId: 'Rol',
                roles0: 'Roles del sistema',
                roles1: 'Roles de negocio',
            },
            fieldGroups: {
                passwordResets: 'Establecer clave de acceso',
                oauthLogin: 'Acceder como este usuario',
            },
            action: {
                reset: 'Visitar link',
                newReset: 'Generar link',
                login: 'Ingresar',
            }
        },
        personas: {
            name: 'Persona |||| Personas',
            fields: {
                cuil: 'CUIL',
                tipoIdentificacionId: 'Tipo de identificación',
                numeroIdentificacion: 'Nro de identificación',
                generoId: 'Género',
                nroTramite: 'Nro de trámite',
                nombre: 'Nombres',
                apellido: 'Apellidos',
                fechaNacimiento: 'Fecha de nacimiento',
                telefono: 'Teléfono',
                email: 'Correo electrónico',
            },
            tabs: {
                general: 'Datos personales',
                asignaciones: 'Asignaciones',
                asignacionescontratadas: 'Contrataciones',
                retribuciones: 'Retribuciones',
                'pagos-ejecutados': 'Pagos',
            },
            buttons: {
                asignaciones: 'Cargar dedicación actual',
                asignacionescontratadas: 'Cargar Contratación',
                retribuciones: 'Cargar Retribución',
                'pagos-ejecutados': 'Cargar Pagos',
            }
        },
        asignaciones: {
            name: 'Asignación |||| Asignaciones',
            fields: {
                jobId: 'Job Description',
            },
            fieldsHelper: {
                horasMensuales: '160 full-time, 80 part-time'
            }
        },
        'asignacion-pivot': {
            name: 'Asignación por mes |||| Asignaciones por mes',
            fields: {
                anio: 'Año',
                mes: 'Mes',
                proyectoId: 'Proyecto',
                personaId: 'Persona',
                jobId: 'Job Description',
                horasMensuales: 'Horas mensuales',
            },
        },
        asignacionescontratadas: {
            name: 'Contratación |||| Contrataciones',
            fieldsHelper: {
                horasMensuales: '160 full-time, 80 part-time'
            }
        },
        'asignacion-contratada-pivot': {
            name: 'Contratación por mes |||| Contrataciones por mes',
            fields: {
                anio: 'Año',
                mes: 'Mes',
                personaId: 'Persona',
                horasMensuales: 'Horas mensuales',
            },
        },
        'business-units': {
            name: 'Unidad de negocio |||| Unidades de negocio',
            fields: {
                name: 'Nombre',
            },
        },
        'categoria-movimiento': {
            name: 'Categoría |||| Categorías',
            fields: {
                code: 'Código único',
                nombre: 'Nombre',
            },
        },
        cuentas: {
            name: 'Cuenta |||| Cuentas',
            fields: {
                inicio: 'Fecha de inicio',
                fin: 'Fecha de fin',
                legalEntityId: 'Entidad Legal',
            },
            buttons: {
                'movimiento-create': 'Agregar'
            }
        },
        'cuentas-personales': {
            name: 'Cuenta Personal |||| Cuentas Personales',
            fields: {
                personaId: 'Persona',
                divisa: 'Divisa',
                tipo: 'Tipo',
                identificador: 'CBU/Nro cuenta',
                detalles: 'Mas información',
            },
            fieldsHelper: {
                tipo: 'CBU, Wise, Cash, SWIFT',
                identificador: 'Nro de CBU, Account Number, "-"',
                detalles: 'Otra información. En caso de SWIFT ingresar: Bank Name, Account Holder Name, Account Number, Routing Number, Checking/Saving, etc.',
            },
        },
        'customers': {
            name: 'Cliente |||| Clientes',
            fields: {
                id: 'Código único',
                name: 'Nombre',
                billingDetails: 'Detalles de facturación',
            },
        },
        dolar: {
            name: 'Cotización USD |||| Cotizaciones USD',
            asColumn: 'Dolar',
            fields: {
                fecha: 'Fecha',
                alternativo: 'Dólar blue',
                oficial: 'Dólar oficial',
            },
            import: {
                dolar: 'Fecha;Alternativo;Oficial'
            }
        },
        ingresos: {
            name: 'Ingreso |||| Ingresos',
            fields: {
                proyectoId: 'Contrato',
                proyectoAsignacionPresupuestaria: 'Proyecto',
                fechaContrato: 'Fecha estimada',
                fechaEmision: 'Fecha de emisión',
                fechaCobro: 'Fecha de cobro',
                nombre: 'Nombre',
                monto: 'Monto',
            },
            fieldsHelper: {
                proyectoAsignacionPresupuestaria: 'Indica qué equipo debe utilizar estos fondos',
                fechaContrato: 'Fecha en que se debería emitir la factura según el contrato',
                fechaEmision: 'Fecha en que se emitió la factura',
                fechaCobro: 'Fecha en que se recibió el dinero',
                fecha: 'Debe existir la cotización del dolar para la fecha deseada',
                nombre: 'Hito, entrega, factura',
                dolar: 'Valor del dolar oficial al cobro (blue)',
            },
        },
        jobs: {
            name: 'Job Description |||| Job Descriptions',
            fields: {
                id: 'Id',
                name: 'Nombre',
            },
            tabs: {
                general: 'Información',
            }
        },
        'legal-entities': {
            name: 'Entidad Legal |||| Entidades Legales',
            fields: {
                name: 'Nombre',
            },
        },
        movimientos: {
            name: 'Movimiento |||| Movimientos',
            import: {
                galicia: 'Galicia (DD/MM/YYYY;DESCRIPCION;-2-;DEBITO;CREDITOS)',
                santander: 'Santander (DD/MM/YYYY;-1-;-2-;-3-;-4-;DESCRIPCION;MONTO)',
                payoneer: 'Payoneer (DD Month, YYYY;DESCRIPCION;MONTO)',
                mercury: 'Mercury (MM-DD-YYYY,DESCRIPCION,MONTO)',
            }
        },
        'pagos-ejecutados': {
            name: 'Pago |||| Pagos',
            fields: {
                personaId: 'Destinatario',
                detalle: 'Detalle',
                fechaEsperada: 'Fecha esperada',
                fechaEjecucion: 'Fecha de ejecución',
                personaProxyId: 'A través de',
                monto: 'Monto',
                neto: 'Monto Neto',
                divisa: 'Divisa',
                estado: 'Estado'
            },
            fieldsHelper: {
                neto: 'Lo que hay que transferir',
            },
        },
        'pago-pivot': {
            name: 'Pago por mes |||| Pagos por mes',
            fields: {
                anio: 'Año',
                mes: 'Mes',
                personaId: 'Persona',
                sueldoPesos: 'Sueldo en pesos',
                sueldoDolares: 'Sueldo en dólares',
            },
        },
        'password-reset': {
            name: 'Blanqueo de contraseña || Blanqueos de contraseña',
        },
        proyectos: {
            name: 'Proyecto |||| Proyectos',
            fields: {
                nombre: 'Nombre',
                customerId: 'Cliente',
                fechaInicio: 'Fecha de inicio',
                fechaFin: 'Fecha de fin',
                legalEntityId: 'Entidad Legal',
                businessUnitId: 'Unidad de negocio',
            },
            tabs: {
                asignaciones: 'Asignaciones',
                general: 'Datos generales',
                ingresos: 'Ingresos del contrato',
                otrosIngresos: 'Aportes del proyecto',
                grafico: 'Resultados'
            },
            action: {
                createIngreso: 'Agregar Ingreso'
            },
        },
        resumenes: {
            name: 'Resumen |||| Resumenes',
        },
        retribuciones: {
            name: 'Retribución |||| Retribuciones',
            fields: {
                legalEntityId: 'Entidad legal',
            },
        },
        'retribucion-pivot': {
            name: 'Retribución por mes |||| Retribuciones por mes',
            fields: {
                anio: 'Año',
                mes: 'Mes',
                personaId: 'Persona',
                sueldoPesos: 'Sueldo en pesos',
                sueldoDolares: 'Sueldo en dólares',
            },
        },
        'retribucion-proyecto-pivot': {
            name: 'Retribución por proyecto por mes |||| Retribuciones por proyecto por mes',
            fields: {
                fecha: 'Fecha',
                personaId: 'Persona',
                proyectoId: 'Proyecto',
                montoPesos: 'Monto en pesos',
                montoDolares: 'Monto en dólares',
            },
        }
    },
    cubes: {
        "MovimientosPorPersona":{
            "a_cobrar_pesos": "Mil pesos",
            "a_cobrar_dolares": "Dólares",
            "deuda_pesos": "Se le debe en pesos",
            "deuda_dolares": "Se le debe en dólares",
            "anio-mes": "Meses atrás",
        },
        "ResumenProyectos": {
            "entidad_nombre": "Entidad",
            "balance_dolares": "Balance",
            "futuro_dolares": "Futuro Ingreso",
            "ingreso_dolares": "Ingreso",
            "sueldo_dolares": "RRHH",
            "gastos_dolares": "Ingreso 🏦",
            "fecha_inicio": "Inicio",
            "fecha_fin": "Fin",
            "fecha_inicio_asignaciones": "Primer asignacion",
            "fecha_fin_asignaciones": "Última asignación",
        }
    }
};

export default messages;