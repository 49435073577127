class ColorAssigner {
    private colors: string[] = [
        "#FF5733", "#3498DB", "#2ECC71", "#F1C40F", "#9B59B6",
        "#E67E22", "#1ABC9C", "#D35400", "#2980B9", "#27AE60",
        "#8E44AD", "#F39C12", "#16A085", "#C0392B", "#95A5A6"
    ];
    private assignedColors: Record<string, string> = {};
    private index: number = 0;

    getColor(key: string): string {
        if (!(key in this.assignedColors)) {
            this.assignedColors[key] = this.colors[this.index % this.colors.length];
            this.index++;
        }
        return this.assignedColors[key];
    }
}

export default ColorAssigner;
