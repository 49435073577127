import React, {useState} from 'react';
import { useRecordContext } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { TODAY } from '../../../libs/sgx/shared/date';
import { YEAR_RANGE } from '../../../layout/dashboard/index';
import { DateFilterComponent } from '../../../libs/sgx/components';
import {
    ColorAssigner,
} from '../../../layout/dashboard/charts';
import LegalEntityPayrollCard from '../../../layout/dashboard/plantilla/LegalEntityPayrollCard';

const LegalEntityPayrollChartField = () => {
    const [selectedYear, setSelectedYear] = useState(TODAY.year.toString());
    const handleDateRangeSelect = (year: string) => {
        setSelectedYear(year);
    };
    const record = useRecordContext();

    const COLOR_MAPPER = new ColorAssigner();

    const legalEntityId = record.id;

    const keyMapper = (key: string) => {
        const detalle = key.split(',')[0];
        return {
            label: detalle,
            borderColor: COLOR_MAPPER.getColor(detalle),
        }
    }

    return <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
          </Grid>
          <Grid item xs={12} md={12}>
            <LegalEntityPayrollCard legalEntityId={legalEntityId} year={selectedYear} keyMapper={keyMapper}></LegalEntityPayrollCard>
          </Grid>
        </Grid>
    </>;
};

export default LegalEntityPayrollChartField;