import React from 'react';
import {
    Resource
} from 'react-admin';
import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import pagosEjecutados from './pagos-ejecutados';
import cuentasPersonales from './cuentas-personales';
import jobs from './jobs';

const resourcesStaff = (permissions: SGXPermissions) => [
    <Resource name="jobs" {...jobs(permissions)} />,
    <Resource name="cuentas-personales" {...cuentasPersonales(permissions)} />,
    <Resource name="pagos-ejecutados" {...pagosEjecutados(permissions)} />,
];

export default resourcesStaff;