import React from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { monthFormatter } from '../../../libs/sgx/shared/date';
import { cubejsApiTokenPromise, cubejsOptions } from './cube-config';

const commonOptions = {
  maintainAspectRatio: false,
};

const cubejsApi = cubejs(cubejsApiTokenPromise, cubejsOptions);

ChartJS.register(...registerables);

const renderChartWithMapper = (keyMapper) => ({ resultSet, error, pivotConfig }) => {
  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return <LinearProgress />;
  }

  const data = {
    labels: resultSet.categories().map((c) => monthFormatter(c.x)),
    datasets: resultSet.series().map((s) => {
      const { label , borderColor } = keyMapper(s.key);
      return {
        label,
        data: s.series.map((r) => r.value),
        borderColor,
        backgroundColor: borderColor,
        fill: true,
      }
    }),
  };
  const options = { ...commonOptions };
  return <Line data={data} options={options} />;

};

const LineDimentionChart = ({
  measures,
  dimensions,
  filters,
  timeDimensions,
  keyMapper,
}) => {

  return (
    <QueryRenderer
      query={{
        measures,
        dimensions,
        timeDimensions,
        filters,
        "order": {}
      }}
      cubejsApi={cubejsApi}
      resetResultSetOnChange={false}
      render={(props) => renderChartWithMapper(keyMapper)({
        ...props,
        chartType: 'line',
        pivotConfig: {
          "x": [
          ],
          "y": [
            "measures"
          ],
          "fillMissingDates": true,
          "joinDateRange": false
        }
      })}
    />
  );
};

export default LineDimentionChart;
