import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    RadioButtonGroupInput,
    ReferenceInput,
} from 'react-admin';

import PersonaInput from '../../personas/PersonaAutocompleteInput';
import { DIVISAS_VALUES } from '../../cuentas/utils';
import { validateTipo } from './utils';

const CuentaPersonalEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput source="personaId" reference="personas" perPage={100} validate={[required()]}>
                <PersonaInput />
            </ReferenceInput>
            <RadioButtonGroupInput source="divisa" choices={DIVISAS_VALUES} validate={[required()]}/>
            <TextInput source="tipo" validate={validateTipo} helperText="resources.cuentas-personales.fieldsHelper.tipo"/>
            <TextInput source="identificador" validate={[required()]} helperText="resources.cuentas-personales.fieldsHelper.identificador"/>
            <TextInput source="detalles" multiline helperText="resources.cuentas-personales.fieldsHelper.detalles"/>
        </SimpleForm>
    </Edit>
);

export default CuentaPersonalEdit;
