import React from 'react';
import {
    ReferenceField,
    Datagrid,
    TextField,
    DateField,
    List,
    NumberField,
    Filter,
    SearchInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';

import PersonaField from '../personas/PersonaField';
import { fullName } from '../personas/utils';


const RetribucionesFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
        <ReferenceInput alwaysOn source="personaId" reference="personas">
            <AutocompleteInput optionText={fullName} />
        </ReferenceInput>
        <ReferenceInput source="legalEntityId" reference="legal-entities" >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const RetribucionesList = props => (
    <List {...props}
        filters={<RetribucionesFilter />} >
        <Datagrid rowClick="show">
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <TextField source="detalle" />
            <DateField source="fechaInicio" locales="es-AR" options={{ timeZone: 'UTC' }}/> 
            <DateField source="fechaFin" locales="es-AR" options={{ timeZone: 'UTC' }}/> 
            <NumberField source="montoPesos" locales="es-AR" options={{style: 'currency', currency: 'ARS'}}/>
            <NumberField source="montoDolares" locales="es-AR" options={{style: 'currency', currency: 'USD'}}/>
            <ReferenceField source="legalEntityId" reference="legal-entities" link={false}>
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);

export default RetribucionesList;