import React from 'react';
import Grid from '@material-ui/core/Grid';
import { monthFormatter, monthCapitalFormatter } from '../../../libs/sgx/shared/date';
import { ChartTableField } from '../../../libs/sgx/components';

const buildColumns = (selectedYear: string, dimention: string, valueFormatter: any) => {
  let columns = [];

  for (let month = 0; month < 12; month++) {
    const formattedMonth = String(month + 1).padStart(2, '0');

    columns.push(
      {
        "field": `${selectedYear}-${formattedMonth}-01T00:00:00.000,${dimention}`,
        "headerName": monthCapitalFormatter(`${selectedYear}-${formattedMonth}-01`),
        "width": 150,
        description: monthFormatter(`${selectedYear}-${formattedMonth}-01`),
        valueFormatter: valueFormatter,
        align: 'right',
    }
    );
  }
  return columns;
}

const RetribucionesPorBusinesUnitMensualTable = ({year, businessUnitId, measures, firstColumn, dimensions, valueFormatter}: {
  businessUnitId: number,
  year: string,
  measures: string,
  firstColumn: any,
  dimensions: string[],
  valueFormatter: any,
}) => {

  const pivotConfig = {
    "x": [
      dimensions[0],
    ],
    "y": [
      "RetribucionesPorProyectos.fecha.month",
      "measures"
    ],
    "fillMissingDates": true,
    "joinDateRange": false
  };

  const timeDimensions = [
    {
      "dimension": "RetribucionesPorProyectos.fecha",
      "granularity": "month",
      "dateRange": [`${year}-01-01`,`${year}-12-31`],
    }
  ];
  
  return (
    <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <ChartTableField
              measures={[
                measures,
              ]}
              timeDimensions={timeDimensions}
              dimensions={dimensions}
              filters={[
                  {
                      "member": "RetribucionesPorProyectos.business_unit_id",
                      "operator": "equals",
                      "values": [
                          `${businessUnitId}`
                      ]
                  },
                  {
                    "member": measures,
                    "operator": "gt",
                    "values": [
                        `0`
                    ]
                  },
              ]}
              order={{
                [measures]: "desc",
              }}
              columns={[
                  firstColumn,
                  ...buildColumns(year, measures, valueFormatter)
              ]}
              pivotConfig={pivotConfig}
          />
        </Grid>
    </Grid>
)};

export default RetribucionesPorBusinesUnitMensualTable;