import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Contrataciones from './charts/Contrataciones';
import {
    monthFormatter,
    isDatePast,
    dateISOFormatter,
} from '../../libs/sgx/shared/date';

import { 
    PieChartField,
} from '../../libs/sgx/components';

const maxToday = (dateStr) => isDatePast(dateStr) ? dateStr : dateISOFormatter(new Date().toISOString());

const ContratacionesCard = (props) => {
    const { personaId, year } = props;

    const personaIdFilters = personaId ? [
        {
            "member": "Contrataciones.persona_id",
            "operator": "equals",
            "values": [
                `${personaId}`
            ]
        }
    ] : [];

    const dateFrom = `${year}-01-01`;
    const dateTo = maxToday(`${year}-12-31`);

    const pieFrom = monthFormatter(dateFrom);
    const pieTo = monthFormatter(dateTo);

    const pieFilters = [
        {
            "member": "Contrataciones.fecha",
            "operator": "inDateRange",
            "values": [dateFrom, dateTo],
        },
        ...personaIdFilters,
    ];

    const timelineFilters = [
        {
            "member": "Contrataciones.fecha",
            "operator": "inDateRange",
            "values": [dateFrom,`${year}-12-31`],
        },
        ...personaIdFilters,
    ];

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
                <Typography gutterBottom variant="h6" display="block">
                    Horas sin asignación
                </Typography>
                <Typography variant="caption" gutterBottom>
                    Desde {pieFrom} hasta {pieTo}
                </Typography>
                <div style={{ height: '40vh' }}>
                    <PieChartField
                        measures={[
                            "Contrataciones.horas_sin_asignar"
                        ]}
                        dimensions={[
                            "Contrataciones.apellido"
                        ]}
                        filters={pieFilters}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography gutterBottom variant="h6" display="block">
                    Utilización de horas
                </Typography>
                <div style={{ height: '40vh' }}>
                    <Contrataciones
                        measures={[
                            "Contrataciones.horas_contratadas",
                            "Contrataciones.horas_retribuidas",
                            "Contrataciones.horas_asignadas",
                        ]}
                        timeDimensions={[
                            {
                                "dimension": "Contrataciones.fecha",
                                "granularity": "month",
                            }
                        ]}
                        filters={timelineFilters}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

export default ContratacionesCard;
