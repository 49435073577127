import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    TabbedShowLayoutTabs,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { ShowFieldTitle } from '../../../libs/sgx/components';
import { PAYROLL_ROLES } from '../../roles-set';
import JobRates from './JobRates';

const useTabsStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100vw',
    },
}));

const JobShow = props => {
    const accesoARetribuciones = props.permissions?.hasAnyAssignment(...PAYROLL_ROLES);
    const classes = useTabsStyles();
    return (
    <Show {...props} title={<ShowFieldTitle source="name"/>} >
        <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="on" className={classes.root} />}>
            <Tab label="resources.jobs.tabs.general">
                <TextField source="name" />

                {accesoARetribuciones && <Tab label="resources.proyectos.tabs.grafico" path="grafico">
                    <JobRates source="id"/>
                </Tab>}
            </Tab>
        </TabbedShowLayout>
    </Show>
)
};

export default JobShow;